$ = jQuery;

$(document).ready(function () {
  setupSlickSlider();
});

function setupSlickSlider(){
  if ($('#jc-review-slider>div').length <= 4) {
      $('#jc-review-slider-next').remove();
      $('#jc-review-slider-prev').remove();
  } else {
      $('#jc-review-slider').slick({
          nextArrow: '#jc-review-slider-next',
          prevArrow: '#jc-review-slider-prev',
          dots: true,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          responsive: [{
              breakpoint: 992,
              settings: "unslick"
          }]
      });
  }

  if ($('#jc-latest-seen-slider>div').length <= 4) {
    $('#jc-latest-seen-slider-next').remove();
    $('#jc-latest-seen-slider-prev').remove();
  } else {
      $('#jc-latest-seen-slider').slick({
          nextArrow: '#jc-latest-seen-slider-next',
          prevArrow: '#jc-latest-seen-slider-prev',
          dots: true,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          responsive: [{
              breakpoint: 992,
              settings: "unslick"
          }]
      });
  }

  if ($('#jc-most-seen-slider>div').length <= 4) {
      $('#jc-review-seen-slider-next').remove();
      $('#jc-review-seen-slider-prev').remove();
  } else {
      $('#jc-most-seen-slider').slick({
          nextArrow: '#jc-review-seen-slider-next',
          prevArrow: '#jc-review-seen-slider-prev',
          dots: true,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          responsive: [{
              breakpoint: 992,
              settings: "unslick"
          }]
      });
  }

  if ($('#jc-offer-slider>div').length <= 4) {
      $('#jc-offer-slider-next').remove();
      $('#jc-offer-slider-prev').remove();
  } else {
      $('#jc-offer-slider').slick({
          nextArrow: '#jc-offer-slider-next',
          prevArrow: '#jc-offer-slider-prev',
          dots: true,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          responsive: [{
              breakpoint: 992,
              settings: "unslick"
          }]
      });
  }

  if ($('#jc-offer-movida-slider>div').length <= 4) {
      $('#jc-offer-movida-slider-next').remove();
      $('#jc-offer-movida-slider-prev').remove();
  } else {
      $('#jc-offer-movida-slider').slick({
          nextArrow: '#jc-offer-movida-slider-next',
          prevArrow: '#jc-offer-movida-slider-prev',
          dots: true,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          responsive: [{
              breakpoint: 992,
              settings: "unslick"
          }]
      });
  }

  $('#jc-prices-slider').slick({
      nextArrow: '#jc-prices-slider-next',
      prevArrow: '#jc-prices-slider-prev',
      dots: true,
      infinite: true,
      slidesToShow: 7,
      slidesToScroll: 7,
      responsive: [{
          breakpoint: 992,
          settings: "unslick"
      }]
  });

  if ($('#jc-brands-slider>div').length <= 6) {
      $('#jc-brands-slider-next').remove();
      $('#jc-brands-slider-prev').remove();
  } else {
      $('#jc-brands-slider').slick({
          nextArrow: '#jc-brands-slider-next',
          prevArrow: '#jc-brands-slider-prev',
          dots: true,
          infinite: true,
          slidesToShow: 6,
          slidesToScroll: 6,
          responsive: [{
              breakpoint: 992,
              settings: "unslick"
          }]
      });
  }

  if ($('#jc-carrocerias-slider>div').length <= 6) {
      $('#jc-carrocerias-slider-next').remove();
      $('#jc-carrocerias-slider-prev').remove();
  } else {
      $('#jc-carrocerias-slider').slick({
          nextArrow: '#jc-carrocerias-slider-next',
          prevArrow: '#jc-carrocerias-slider-prev',
          dots: true,
          infinite: true,
          slidesToShow: 6,
          slidesToScroll: 6,
          responsive: [{
              breakpoint: 992,
              settings: "unslick"
          }]
      });
  }

  if ($('#jc-lifestyles-slider>div').length <= 6) {
      $('#jc-lifestyles-slider-next').remove();
      $('#jc-lifestyles-slider-prev').remove();
  } else {
      $('#jc-lifestyles-slider').slick({
          nextArrow: '#jc-lifestyles-slider-next',
          prevArrow: '#jc-lifestyles-slider-prev',
          dots: true,
          infinite: true,
          slidesToShow: 6,
          slidesToScroll: 6,
          responsive: [{
              breakpoint: 992,
              settings: "unslick"
          }]
      });
  }

  $('#jc-image-gallery-slider').slick({
      nextArrow: '#jc-image-gallery-slider-next',
      prevArrow: '#jc-image-gallery-slider-prev',
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [{
          breakpoint: 992,
          settings: "unslick"
      }]
  });

  $('#jc-image-catalog-slider').slick({
      nextArrow: '#jc-image-catalog-slider-next',
      prevArrow: '#jc-image-catalog-slider-prev',
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [{
          breakpoint: 992,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          }
      }]
  });

  $('#jc-related-videos-slider').slick({
      nextArrow: '#jc-related-videos-slider-next',
      prevArrow: '#jc-related-videos-slider-prev',
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [{
          breakpoint: 992,
          settings: "unslick"
      }]
  });

  $('#jc-related-galleries-slider').slick({
      nextArrow: '#jc-related-galleries-slider-next',
      prevArrow: '#jc-related-galleries-slider-prev',
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [{
          breakpoint: 992,
          settings: "unslick"
      }]
  });

  $('.jc-image-slider').slick({
      nextArrow: '<button class="btn btn-primary btn-circle mr-3"><i class="jc-icon jc-icon-sm jc-icon-arrow-right"></i></button>',
      prevArrow: '<button class="btn btn-primary btn-circle ml-3"><i class="jc-icon jc-icon-sm jc-icon-arrow-left"></i></button>',
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [{
          breakpoint: 992,
          settings: {
              arrows: false
          }
      }]
  });

  $('.jc-image-slider-auto').slick({
      nextArrow: '',
      prevArrow: '',
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [{
          breakpoint: 992,
          settings: {
              arrows: false
          }
      }]
  });

  $('.jc-img-gallery-wrapper').each(function() {
      var galleryId = $(this).prop('id');
      $(this).find('.jc-img-gallery').before('<div class="d-flex justify-content-between align-items-center mb-4"> <h3 class="h4 h3-lg font-weight-bold mb-0"></h3> <div class="d-none d-lg-flex"> <button id="jc-slider-prev-' + galleryId + '" class="btn btn-primary btn-circle mr-3 slick-arrow slick-hidden" aria-disabled="true" tabindex="-1"><i class="jc-icon jc-icon-sm jc-icon-arrow-left"></i></button> <button id="jc-slider-next-' + galleryId + '" class="btn btn-primary btn-circle slick-arrow slick-hidden" aria-disabled="true" tabindex="-1"><i class="jc-icon jc-icon-sm jc-icon-arrow-right"></i></button> </div> </div>');
      $(this).find('.jc-img-gallery').slick({
          nextArrow: '#jc-slider-next-' + galleryId,
          prevArrow: '#jc-slider-prev-' + galleryId,
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          responsive: [{
              breakpoint: 992,
              settings: "unslick"
          }]
      });
  })
}
